import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from 'components/layout';
import Head from 'components/head';
import { Box } from 'grommet';
import SectionTitle from 'components/title/sectionTitle';
import Column1 from '../../content/publications/publicationslist.mdx';



const Publications = ({ data }) => (
  <Layout>
    <Head pageTitle={data.publicationsJson.title} />
    <Box fill="horizontal">
    
 
    
      <Box width="xlarge" alignSelf="center" background="light-3" >
        <SectionTitle title="Publications" />
        <Box alignSelf="center" direction="row-responsive">
          <Box width="xlarge" margin="small">
            <Column1 />
          </Box>
          
          
          
        </Box>
      </Box>
    </Box>
  </Layout>
);

Publications.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Publications;

export const query = graphql`
  query publicationsQuery {
    publicationsJson {
      title
    }
  }
`;




